<template>
  <div class="register-page">
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="main-title">用人单位账号注册</div>
        <div class="register-step unable-selected">
          <div class="register-step-item is-complete">
            <div class="register-step-item-icon " :class="step === 1 ? 'is-complete-icon' : step > 1 ? 'is-complete-icon-check' : ''">1</div>
            <span>单位信息</span>
            <div class="register-step-item-separator"></div>
          </div>
          <div class="register-step-item" :class="step !== 1 ? 'is-complete' : ''">
            <div class="register-step-item-icon" :class="step === 2 ? 'is-complete-icon' : step > 2 ? 'is-complete-icon-check' : ''">2</div>
            <span>账号信息</span>
            <div class="register-step-item-separator"></div>
          </div>
          <div class="register-step-item" :class="step === 3 ? 'is-complete' : ''">
            <div class="register-step-item-icon" :class="step === 3 ? 'is-complete-icon' : ''">3</div>
            <span>完成注册</span>
          </div>
        </div>
        <el-form class="zwx-form edit-form" key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" @submit.native.prevent>
          <div class="employer-content" v-show="step === 1">
            <div>
              <el-form-item label-width="110px" label="企业名称：" prop="unitName">
                <unit-select width="416px" searchUnitName="unitNameLike" :isPost="true" :isSms4="true" ref="unitSelect" v-model="mainForm.unitName" otherRequestUrl="/data/exchange/getUnitListFromEnterprise-0" placeholderText="请输入5个字符搜索" :requestUrl="requestUrl" :ifAuthority="true" @unitNameSearchOrDel="unitNameDel" @change="unitNameSelect"  @loading="data => useloading(data)"/>
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
                <el-input style="width:416px !important" v-model="mainForm.creditCode" placeholder="请输入社会信用代码" clearable :disabled="creditCodeDisabled" />
              </el-form-item>
            </div>
            <div style="width:526px;text-align:left;">
              <el-form-item label-width="110px" label="是否分支机构：" prop="ifBranch">
                <el-radio-group class="zwx-radio-group" v-model="mainForm.ifBranch">
                  <el-radio class="zwx-radio" :label="false">否</el-radio>
                  <el-radio class="zwx-radio" :label="true">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="mainForm.ifBranch">
              <el-form-item label-width="110px" label="上级机构名称：" prop="parentUuid">
                <unit-select width="416px" :isPost="true" searchUnitName="unitNameLike" :ifShowMoreBtn="false"  v-model="mainForm.unitParentName" placeholderText="请输入5个字符搜索" :isSms4="true" requestUrl="/basic/info/getParentUnitInfoByKeyword-0" :ifAuthority="true" @unitNameSearchOrDel="unitParentDel" :ifNeedMore="false" @unitNameSearch="unitNoSearch" @change="unitParentSelect" @loading="data => useloading(data)" />
              </el-form-item>
            </div>
          
            <div>
              <el-form-item label-width="110px" label="作业场所地区：" prop="bizZone">
                <zwx-select-area class="cascader-area-biz-zone select-component" ref="bizZoneArea" placeholder="请选择" @change="workPlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="作业场所地址：" prop="workplaceAddr">
                <el-input style="width:416px !important" v-model="mainForm.workplaceAddr" placeholder="请输入" maxlength="400" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="联系人：" prop="name">
                <el-input style="width:416px !important" v-model="mainForm.name" placeholder="请输入" maxlength="100" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="联系人电话：" prop="mobileTel">
                <el-input style="width:416px !important" v-model="mainForm.mobileTel" placeholder="请输入" maxlength="11" clearable />
              </el-form-item>
            </div>
            <div style="width: 526px;text-align:right">
              <el-button class="zwx-button prev-button" @click="back">上一步</el-button>
              <el-button class="zwx-button next-button" type="primary" @click="nextStep">
                下一步
              </el-button>
            </div>
          </div>
          <div class="employer-content" v-if="step === 2">
           
            <div class="ms-name" v-if="mainForm.registerType == 1">
              <el-form-item label-width="110px" label="姓名：" prop="user">
                <el-select class="zwx-select2" v-model="mainForm.user" style="width:416px !important" placeholder="请选择" clearable @change="changeUserInfo">
                  <el-option v-for="item in userInfoList" :key="item.uid" :label="item.userName" :value="item.uid">
                    <div class="ms-options">
                      <span>{{ item.userName }}</span>
                      <span>{{ phoneHide($zwxSm.sm4JsDecrypt(item.mobileTel)) }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-else>
              <div>
              <el-form-item label-width="110px" label="登录账号：" prop="userNo">
                <el-input style="width:416px !important" v-model="mainForm.userNo" placeholder="请输入" maxlength="11" clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="登录密码：" prop="password">
                <el-input style="width:416px !important" v-model="mainForm.password" :type="newView ? 'password' : null" placeholder="请输入" maxlength="16">
                  <i slot="suffix" :class="newView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeNew()"></i>
                </el-input>
                <div class="password-level unable-selected" v-show="mainForm.password !== ''" :class="mainForm.passwordLevel === 3 ? 'higher' : mainForm.passwordLevel === 2 ? 'middle' : 'lower'">
                  <div class="password-level-box">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="password-level-text">安全等级：</div>
                  <div class="password-level-code">
                    {{ mainForm.passwordLevel === 3 ? '高' : mainForm.passwordLevel === 2 ? '中' : '低' }}
                  </div>
                </div>
              </el-form-item>
            </div>
            <div>
              <el-form-item label-width="110px" label="确认密码：" prop="confirmPassword">
                <el-input style="width:416px !important" v-model="mainForm.confirmPassword" placeholder="请输入" maxlength="20" :type="confirmView ? 'password' : null">
                  <i slot="suffix" :class="confirmView ? 'el-input__icon  outline chis-icon-outline-eye' : 'el-input__icon outline chis-icon-outline-eye-invisible'" @click="passSeeConfirm()"></i>
                </el-input>
                <div class="confirmPassword-pass" v-show="$zwxBase.verifyIsNotBlank(mainForm.password) && $zwxBase.verifyIsNotBlank(mainForm.confirmPassword) && mainForm.password == mainForm.confirmPassword" :class="$zwxBase.verifyIsNotBlank(mainForm.password) && $zwxBase.verifyIsNotBlank(mainForm.confirmPassword) && mainForm.password == mainForm.confirmPassword ? 'filled chis-icon-filled-completed' : ''" style="color:#10C77B"></div>
              </el-form-item>
            </div>
            </div>
           
            <div style="padding-left:110px;">
              <image-verification ref="loginVerification" :uid="uid" width="415px" @success="msEnterpriseLoginSuccessVerification"></image-verification>
              <div class="warningtext">{{ tip }}</div>
            </div>
            <div style="margin-top: 20px;">
              <el-form-item label-width="110px" label="短信验证码：" prop="verifyCode">
                <el-input style="width:416px " v-model="mainForm.verifyCode" clearable placeholder="请输入短信验证码">
                  <template slot="append">
                    <div class="msg-button" @click="send" :style="showtime !== null ? 'pointer-events: none' : 'cursor: pointer;'">
                      <span v-if="showtime === null" :style="{color:isSucess?'#0a4dea !important':'#909399'}">
                        发送
                      </span>
                      <span v-else style="color: #0a4dea!important">
                        {{ showtime }}
                      </span>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="footer-button">
              <el-button class="zwx-button prev-button" @click="prevStep">上一步</el-button>
              <el-button class="zwx-button next-button" type="primary" @click="saveRegister">确认</el-button>
            </div>
          </div>
          <div class="account-content" v-if="step === 3">
            <div class="register-success"></div>
            <div class="register-success-title">你已成功注册</div>
            <div style="margin-top:38px"><el-button class="zwx-button next-success" type="primary" @click="toLogin">去登录</el-button></div>
          </div>
        </el-form>
      </el-main>
      <div class="bottom" style="height:calc(100% - 900px);">
        <div class="bottom-text">Copyright © {{ domainNumber }} | {{ technicalSupport }}</div>
      </div>
    </el-container>
    <register-valid-dialog ref="registerValidDialog" :unitName="mainForm.unitName" :endNumber="endNumber" />
  </div>
</template>
<script>
import imageVerification from '@/components/business/imageVerification'
import registerValidDialog from './components/validDialog.vue'
export default {
  name: 'Register',
  components: { imageVerification,registerValidDialog  },
  data() {
    return {
      api: this.$store.state.api,
      domainNumber: this.$store.state.domainNumber,
      technicalSupport: this.$store.state.technicalSupport,
      step: 1,
      loading: null,
      tip: '',
      isSucess: false,
      uid: this.baseGenUuid(32),
      zoneCode12From: '000000000000',
      // 计时器,注意需要进行销毁
      timeCounter: null,
      // null 则显示按钮 秒数则显示读秒
      showtime: null,
      mainForm: {
        unitName: '',
        clauseCheck: false,
        creditCode: '',
        zoneType: '',
        registerZone: '',
        registerAddr: '',
        bizZone: '',
        bizZoneType: '',
        workplaceAddr: '',
        name: '', // 姓名
        userNo: '',
        mobileTel: '',
        password: '',
        passwordLevel: 0,
        confirmPassword: '',
        legalPerson: '',
        ifBranch: false,
        unitParentName: '', //存在上级机构时，父级机构名称
        parentUuid: '', //存在上级机构时，父级单位uuid
        //验证码
        verifyCode: '',
        smsVerifyCodeUuid: '',
      },
      requestUrl: '/systematic/getUnitByUnitNameLike-0',
      creditCodeDisabled: false,
      newView: true,
      confirmView: true,
      unitUid:null,
      rules: {
        user: [{ required: true, message: '请选择账号', trigger: ['change', 'blur'] }],
        unitName: [
          { required: true, message: '请输入单位名称', trigger: ['blur'] },
          { validator: this.validateUnitName, trigger: ['blur'] },
        ],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['change'] },
          { validator: this.validateCreditCode, trigger: ['blur'] },
        ],
        ifBranch: [{ required: true, message: '请选择是否分支机构', trigger: ['change', 'blur'] }],
        parentUuid: [{ required: false, message: '请选择单位名称', trigger: ['change', 'blur'] }],
        registerZone: [
          { required: true, message: '请选择注册地区', trigger: ['change'] },
          { validator: this.validateRegisterZoneZone, trigger: ['blur', 'change'] },
        ],
        registerAddr: [{ required: true, message: '请输入注册地址', trigger: ['blur', 'change'] }],
        bizZone: [
          { required: true, message: '请选择作业场所地区', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        workplaceAddr: [{ required: true, message: '请输入作业场所地址', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: '请输入真实姓名', trigger: ['change', 'blur'] }],
        legalPerson: [{ required: true, message: '请输入法人代表', trigger: ['change', 'blur'] }],
        userNo: [
          { required: true, message: '请输入登录账号', trigger: ['change', 'blur'] },
          { validator: this.validateUserNo, trigger: ['blur'] },
        ],
        mobileTel: [
          { required: true, message: '请输入手机号码', trigger: ['change', 'blur'] },
          // { validator: this.validateMobile },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['change', 'blur'] },
          { validator: this.validatePasswordLevel, trigger: ['change', 'blur'] },
        ],
        confirmPassword: [
          { required: true, message: '请输入密码', trigger: ['change', 'blur'] },
          { required: true, validator: this.confirmPasswordRules, trigger: ['change', 'blur'] },
        ],
        verifyCode: [{ required: true, message: '请输入短信验证码', trigger: ['change', 'blur'] }],
      },
      userInfoList: [],
      userInfoMap: {},
       selectUser:{},
      isCheck:false,
      endNumber:null,
    }
  },
  created() {
    this.$system.get(
      this.api + '/systematic/getParameterListByParameterKeys-0',
      { parameterKeys: 'SYSTEM_ZONE_CODE' },
      false,
      true,
      data => {
        if (data.type === '00' && data.parameterList.length > 0) {
          this.zoneCode12From = data.parameterList[0].parameterValue
          this.$nextTick(()=>{
            this.$refs.bizZoneArea.initSearch()   
          })
          
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$notify.error({ title: '错误', message: data.mess })
        }
      },
      this.error
    )

    // this.zoneCode12From = '000000000000'
  },
  mounted() {
    // this.$refs.registerZoneArea.initSearch()
    // this.$refs.bizZoneArea.initSearch()
    let fun = this.$refs.unitSelect.cancelUnitSearchDialog
    this.$nextTick(() => {
      this.$refs.unitSelect.cancelUnitSearchDialog = () => {
        this.$refs.unitSelect.unitNameSearchVisible = false
        fun()
      }
    })
  },
  watch: {
    // 分支机构为true   则上级机构校验为true
    'mainForm.ifBranch'() {
      if (this.mainForm.ifBranch) {
        this.rules.parentUuid[0].required = true
      }
    },
    mainForm: {
      handler() {
        if (this.mainForm.unitName != '' && this.mainForm.creditCode != '' && (!this.mainForm.ifBranch || (this.mainForm.ifBranch && this.mainForm.parentUuid != '')) && this.mainForm.registerZone != '' && this.mainForm.registerAddr != '' && this.mainForm.bizZone != '' && this.mainForm.workplaceAddr != '' && this.mainForm.legalPerson != '') {
          this.mainForm.clauseCheck = true
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    unitNoSearch(){
      this.$system.notify('警告', '请先注册上级机构', 'warning')
    },
      // 手机号做脱敏处理
      phoneHide(phone) {
      let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/ // 定义手机号正则表达式
      phone = phone.replace(reg, '$1****$2')
      return phone // 185****6696
    },
    useloading(data){
      if(data){
        this.loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.3)',
      })
      }else{
        this.loading && this.loading.close()
        this.loading = null
      }
    },
    //重置图形验证码
    resetLoginVerification() {
      this.uid = this.baseGenUuid(32)
      this.$nextTick(function() {
        this.$refs['loginVerification'].refresh()
      })
      this.isSucess = false
    },
    //图形验证成功返回值
    msEnterpriseLoginSuccessVerification(val) {
      if (val.isSucess) {
        this.tip = ''
      }
      this.isSucess = val.isSucess
    },
    toLogin() {
      this.$router.push({ path: '/login',query:{loginType:1} })
    },
    toWebsite() {
      this.$router.push({ path: '/' })
    },
    changeUserInfo(val) {
      this.selectUser = this.userInfoMap[val]
    },
    //机构清空回调方法
    unitNameDel(type) {
      if(type=='del'){
        this.creditCodeDisabled = false
      this.searchUnitList = []
      this.mainForm.legalPerson = ''
      this.mainForm.linkMobile = ''
      this.mainForm.email = ''
      this.mainForm.linkMan = ''
      this.mainForm.telephone = ''
      this.mainForm.creditCode = ''
      this.mainForm.unitName = ''
      this.mainForm.annexList1 = []
      this.mainForm.annexList2 = []
      this.mainForm.annexList3 = []
      this.unitNameSearchVisible = false
      this.mainForm.unitName=''
      this.mainForm.creditCode=''
      this.mainForm.user=null
      this.$nextTick(() => {
        this.$refs['mainForm'].clearValidate()
      })
      }
     
    },
    // 单位信息选择回调方法
    unitNameSelect(item) {
      //客户信息
      this.mainForm.unitName = item.unitName
      this.mainForm.creditCode = item.creditCode
      if (this.mainForm.workAddr) {
          this.mainForm.workAddr = item.unitAddr
        }
      if (item.fkByZoneId) {
        this.$refs.bizZoneArea.initSearch(item.fkByZoneId.zoneCode12)
      }
      if(item.unitAddr){
        this.$set(this.mainForm, 'workplaceAddr', item.unitAddr)
      }
      
      if (this.$zwxBase.verifyIsNotBlank(this.mainForm.creditCode)) {
        this.creditCodeDisabled = true
      } else {
        this.creditCodeDisabled = false
      }
      
      if (item.uid) {
         this.mainForm.unitUid=item.uid
        }
      this.unitNameSearchVisible = false
    },
    //父级机构清空
    unitParentDel() {
      this.mainForm.unitParentName = ''
      this.mainForm.parentUuid = ''
    },
    //父级机构单位信息选择
    unitParentSelect(item) {
      this.mainForm.unitParentName = item.unitName
      this.mainForm.parentUuid = item.unitUid
      if (!this.mainForm.parentUuid) {
        this.$system.notify('警告', '请先进行上级单位注册', 'warning')
        this.$refs.mainForm.validateField('parentUuid')
      }else{
        this.$refs.mainForm.validateField('parentUuid')
      }

    },
    passSeeNew() {
      this.newView = !this.newView
    },
    passSeeConfirm() {
      this.confirmView = !this.confirmView
    },
    prevStep() {
      this.step--
      // 关闭查询单位下拉
      // this.unitNameSearchVisible = false
      // this.$nextTick(() => {
      //   this.$refs['mainForm'].clearValidate()
      // })
    },

    nextStep() {
      if (this.$refs.unitSelect) {
        this.$refs.unitSelect.unitNameSearchVisible = false
      }

      this.$refs['mainForm'].validate(async valid => {
        if (valid) {
          await this.getIfUnitExist()
          this.ifMobileExist2(this.mainForm.mobileTel,()=>{
            this.step=2
            this.$nextTick(() => {
              this.resetLoginVerification()
            })
         
          })
        

          // let data = {
          //   creditCode: this.$zwxSm.sm4JsEncrypt(this.mainForm.creditCode),
          //   unitName: this.$zwxSm.sm4JsEncrypt(this.mainForm.unitName),
          //   unitUid:this.mainForm.unitUid
          // }
          // this.$system.post(
          //   this.api + '/basic/info/findEmployerBasicByTwoData-0',
          //   data,
          //   false,
          //   true,
          //   data => {
          //     if (data.type === '00') {
          //       if (data.basicSize !== 0) {
          //         this.$notify.error({ title: '错误', message: '此企业单位已被注册' })
          //         result = false
          //       }
          //       this.step++
          //       this.$nextTick(() => {
          //         this.resetLoginVerification()
          //       })
          //     } else if (data.type === '99') {
          //       this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          //     } else {
          //       this.$notify.error({ title: '错误', message: data.mess })
          //     }
          //   }
          //   // this.error
          // )
         
        }else{
        }
      })

      // }
    },
    getIfUnitExist() {
      return new Promise(resolve => {
        let data = {
          unitName: this.mainForm.unitName,
          creditCode: this.mainForm.creditCode,
          ifBranch:this.mainForm.ifBranch
        }

        this.$system.post(this.api + '/basic/info/ifUnitExist-0', data, true, true, data => {
          if (data.type === '00') {
            if (data.mobileTel4) {
              this.endNumber = data.mobileTel4
              this.$refs.registerValidDialog.openDialog()
              this.isSuccess = false
              return
            }
            const { ifExist, userInfoList = [], unitUid } = data
            this.userInfoList = userInfoList.map(item => {
              this.userInfoMap[item.uid] = item
              return item
            })
            this.unitUid = unitUid
            this.mainForm.registerType = userInfoList.length != 0 && ifExist ? 1 : 0
            this.ifExist = ifExist
            resolve(data)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.isSuccess = false
            this.$notify.error({ title: '错误', message: data.mess })
          }
        })
      })
    },
    //发送验证码
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后重新获取`
    },
    countDown(times) {
      const self = this
      // 时间间隔 1秒
      const interval = 1000
      let count = 0
      self.timeCounter = setTimeout(countDownStart, interval)

      function countDownStart() {
        if (self.timeCounter == null) {
          return false
        }
        count++
        self.countDownText(times - count + 1)
        if (count > times) {
          clearTimeout(self.timeCounter)
          self.showtime = null
        } else {
          self.timeCounter = setTimeout(countDownStart, interval)
        }
      }
    },
    //点击发送
    send() {
      if (!this.mainForm.mobileTel && !this.mainForm.registerType) {
        this.$refs['mainForm'].validateField('mobileTel')
        return
      }
      if (this.mainForm.registerType && !this.selectUser.uid) {
        this.$refs['mainForm'].validateField('user')
        return
      }
      if (this.isSucess) {
        this.tip = ''
      } else {
        this.tip = '请先完成验证'
        return
      }
      // this.$refs['mainForm1'].validateField('mobileTel', (err) => {
      //   if (!err) {
      this.loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.3)',
      })
      let data = {
        receivePhone: this.mainForm.registerType ? this.selectUser.mobileTel : this.$zwxSm.sm4JsEncrypt(this.mainForm.mobileTel),
        receiveName: this.mainForm.registerType ? this.selectUser.userName : this.mainForm.name,
      }
    

      // 发送短信验证码
      this.$system.postJson(
        this.api + '/data/exchange/smsSendVerifyCode-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            this.countDown(60)
            this.mainForm.smsVerifyCodeUuid = data.uuid
            this.$system.notify('成功', data.mess, 'success')
          } else if (data.type === '99') {
            this.$refs.verify.imgCodeReload()
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$refs.verify.imgCodeReload()
            this.$system.notify('错误', data.mess, 'error')
          }
          this.loading.close()
          this.loading = null
        },
        this.error
      )
    },
    /**
     * 保存注册
     */
    saveRegister() {
      if (this.isSucess) {
        this.tip = ''
      } else {
        this.tip = '拼图验证错误'
        return
      }
      this.$refs['mainForm'].validate(valid => {
        if (valid) {
          this.resetLoginVerification()
          this.loading = this.$loading({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.3)',
          })
          let userInfo = {
            userName: this.mainForm.name,
            password: this.$zwxSm.sm2Encrypt123(this.mainForm.password),
            userNo: this.$zwxSm.sm4JsEncrypt(this.mainForm.userNo),
            mobileTel: this.$zwxSm.sm4JsEncrypt(this.mainForm.mobileTel),
            confirmPassWord: this.$zwxSm.sm2Encrypt123(this.mainForm.confirmPassword),
          }
          let data = {
            ifBranch: this.mainForm.ifBranch,
            unitName: this.mainForm.unitName,
            creditCode: this.mainForm.creditCode,
            legalPerson: this.mainForm.legalPerson,
            registerZone: this.mainForm.registerZone,
            workplaceAddr: this.mainForm.workplaceAddr,
            registerAddr: this.mainForm.registerAddr,
            bizZone: this.mainForm.bizZone,
            tsUserInfoExp: userInfo,
            smsVerifyCodeUuid: this.mainForm.smsVerifyCodeUuid,
            smsVerifyCode: this.mainForm.verifyCode,
            email: this.mainForm.email,
            linkMan: this.mainForm.name,
          }
          if (this.mainForm.unitUid) {
            data.unitUid = this.mainForm.unitUid
          }
          if(this.mainForm.parentUuid){
            data.parentUuid=this.mainForm.parentUuid
          }
          data.smsVerifyCode = this.mainForm.verifyCode
          if (this.mainForm.registerType) {
            data.tsUserInfoExp = { 
              mobileTel: this.selectUser.mobileTel,
              uid: this.selectUser.uid
             }
            // data.userUid = this.selectUser.uid
          }
          this.$system.postJson(
            this.api + '/basic/info/addRegisterEmployer-0',
            data,
            false,
            true,
            data => {
              if (data.type === '00') {
                this.step++
                this.loading.close()
                this.loading = null
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                this.loading.close()
                this.loading = null
              } else {
                this.$notify.error({ title: '错误', message: data.mess })
                this.loading.close()
                this.loading = null
              }
            },
            this.error
          )
        }
      })
    },
    //注册地区
    nativePlaceChange() {
      this.mainForm.registerZone = this.$refs.registerZoneArea.getChooseArea() == null ? null : this.$refs.registerZoneArea.getChooseArea().zoneCode12
      this.mainForm.zoneType = this.$refs.registerZoneArea.getChooseArea() == null ? null : this.$refs.registerZoneArea.getChooseArea().zoneType
    },
    // 作业场所地区
    workPlaceChange() {
      this.mainForm.bizZone = this.$refs.bizZoneArea.getChooseArea() == null ? null : this.$refs.bizZoneArea.getChooseArea().zoneCode12
      this.mainForm.bizZoneType = this.$refs.bizZoneArea.getChooseArea() == null ? null : this.$refs.bizZoneArea.getChooseArea().zoneType
    },
    /**
     * 登录账号重复性校验
     */
    validateUserNo(rule, value, callback) {
      let data = {
        userNo: this.$zwxSm.sm4JsEncrypt(value),
      }
      this.$system.post(
        this.api + '/systematic/getUserNoCount-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            if (data.userNoCount !== 0) {
              // this.$notify.error({ title: '错误', message: '此登录账号已被注册' })
              callback('此登录账号已被注册')
            } else {
              callback()
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    /**
     * 手机号重复性校验
     */
    ifMobileExist(rule, value, callback) {
      // 手机号(账号)重复性校验
      let data = {
        mobileTel: this.$zwxSm.sm4JsEncrypt(value),
      }
      this.$system.post(
        this.api + '/systematic/getUserCountByMobileTel-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            if (data.basicSize !== 0) {
              callback('此号码已被注册')
            } else {
              callback()
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    ifMobileExist2(value,callback) {
      // 手机号(账号)重复性校验
      let data = {
        mobileTel: this.$zwxSm.sm4JsEncrypt(value),
      }
      this.$system.post(
        this.api + '/systematic/getUserCountByMobileTel-0',
        data,
        false,
        true,
        data => {
          if (data.type === '00') {
            if (data.userCount !== 0) {
              this.$notify.error({ title: '错误', message: '该手机号已注册' })
             
            } else {
              callback()
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    /**
     * 单位名称长度校验
     */
    validateUnitName(rule, value, callback,error) {
      if (value != undefined && value != null && value != '' && value.length < 5) {
        callback(new Error('至少输入5个字符进行检索'))
      }else{
         callback()
      }
    },
    validateRegisterZoneZone(rule, value, callback) {
      if (this.mainForm.zoneType < 3) {
        callback(new Error('注册地区范围精确到区县级或者街道级'))
      }
      callback()
    },
    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(this.$refs.bizZoneArea.getChooseArea())) {
        return callback(new Error('请选择'))
      }
      let zoneType = this.$refs.bizZoneArea.getChooseArea().zoneType
      let childNum = this.$refs.bizZoneArea.getChooseArea().childNum
      if (this.$zwxBase.verifyIsNotBlank(zoneType) && this.$zwxBase.verifyIsNotBlank(childNum)) {
        if (childNum != 0) {
          return callback(new Error('请选择到最下级'))
        } else {
          if (zoneType > 4) {
            return callback(new Error('请选择到街道级'))
          }
        }
      }
      return callback()
    },
    /**
     * 社会信用代码校验
     */
    validateCreditCode(rule, value, callback) {
      if (this.mainForm.ifBranch == false) {
        if (this.$zwxBase.verifyIsNotBlank(value)) {
          this.$validate.creditCode(rule, value, callback)
          var result = true
          let data = {
            creditCode: this.$zwxSm.sm4JsEncrypt(value),
            unitName: this.$zwxSm.sm4JsEncrypt(this.mainForm.unitName),
          }
          if (!this.mainForm.unitName) {
            return
          }
          // this.$system.post(
          //   this.api + '/basic/info/findEmployerBasicByTwoData-0',
          //   data,
          //   false,
          //   true,
          //   data => {
          //     if (data.type === '00') {
          //       if (data.basicSize !== 0) {
          //         this.$notify.error({ title: '错误', message: '此企业单位已被注册' })
          //         result = false
          //       }
          //       callback(result)
          //     } else if (data.type === '99') {
          //       this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          //     } else {
          //       this.$notify.error({ title: '错误', message: data.mess })
          //     }
          //   }
          //   // this.error
          // )
        }
      }

      callback()
    },
    /**
     * 手机号校验
     */
    validateMobile(rule, value, callback) {
      var patt = /^[1][3-9][\d]{9}/
      if (!patt.test(value)) {
        callback(new Error('请输入正确手机号'))
      } else {
        
        if(!this.isCheck){
          // this.ifMobileExist(rule, value, callback)
          this.isCheck=true
        }
        callback()
     
      }
      callback()
    },
    /**
     * 密码强度校验
     */
    validatePasswordLevel(rule, value, callback) {
      if (value.length < 8) {
        this.mainForm.passwordLevel = 1
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      let lev = 0
      //如果密码中存在数字，强度加一
      if (value.match(/\d+/g)) {
        lev++
      }
      //如果密码中存在小写字母，强度加一
      if (value.match(/[a-z]+/g) && value.match(/[A-Z]+/g)) {
        lev++
      }
      //如果密码中存在特殊字符，强度加一
      if (value.match(/[^a-zA-Z0-9]+/g)) {
        lev++
      }
      var arr = value.split('')
      for (var i = 0; i < arr.length - 2; i++) {
        var char1 = arr[i].charCodeAt()
        var char2 = arr[i + 1].charCodeAt()
        var char3 = arr[i + 2].charCodeAt()
        if ((char1 + 1 == char2 && char2 + 1 == char3) || (char1 - 1 == char2 && char2 - 1 == char3) || (char1 == char2 && char2 == char3)) {
          return callback(new Error('不能包含连续3位顺序或逆序或重复的数字、字母'))
        }
      }
      this.mainForm.passwordLevel = lev
      if (lev < 2) {
        callback(new Error('密码应为8-16位，必须包含大写字母、小写字母和数字'))
      }
      callback()
    },
    /**
     * 密码校验
     */
    confirmPasswordRules(rule, value, callback) {
      if (!rule.required && (value === undefined || value === null || value.length === 0)) return callback()
      if (this.mainForm.password !== this.mainForm.confirmPassword) return callback(new Error('两次输入的密码不一致'))
      return callback()
    },
    /**
     * 生成uuid
     * len：uuid长度
     *
     * */
    baseGenUuid(len) {
      var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
      var uuid = [],
        i
      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * chars.length)]
      } else {
        var r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
          if (typeof uuid[i] === 'undefined') {
            r = 0 | (Math.random() * 16)
            uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
          }
        }
      }
      return uuid.join('')
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.loading.close()
      this.loading = null
    },
    back() {
      this.$router.push({ name: 'selectRegister', params: { selectUnit: 1 } })
    },
  },
}
</script>

<style lang="less" scoped>
.ms-options {
  display: flex;
  justify-content: space-between;
}
/deep/.el-input__inner {
  padding: 0 7px;
}
/deep/ .el-input__inner::placeholder {
  color: #7e91a5;
}
.warningtext {
  padding-top: 5px;
  height: 15px;
  color: #ff4949;
  font-size: 12px;
  line-height: 15px;
}
.register-page {
  height: 100%;
  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    color: #999999;
    .bottom-text {
      position: fixed;
      bottom: 10px;
    }
  }
}

.main-header {
  height: 85px !important;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 4px rgba(222, 227, 236, 0.8);
  z-index: 2;
}

.main-title {
  width: 100%;
  text-align: center;
  mix-blend-mode: normal;
  color: #3d3f44;
  font-size: 24px;
  margin: 32px 0;
}

.register-step {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  &-item {
    display: flex;
    line-height: 28px;
    color: #99adc5;

    &-icon {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #99adc5;
      border: 1px solid #99adc5;
      border-radius: 50%;
      margin-right: 7px;
      font-family: 'Harmony Bold';
    }

    &-separator {
      width: 117px;
      height: 1px;
      background: #99adc5;
      margin: auto 10px;
    }
  }
  &-item.is-complete {
    color: #1ca06a;
  }
  &-item-icon.is-complete-icon {
    background: #1ca06a;
    color: #fff;
  }
  &-item-icon.is-complete-icon-check {
    background: rgba(255, 255, 255, 1);
    border-color: rgba(28, 160, 106, 1);
  }
}

.employer-content,
.account-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /deep/.el-icon-circle-close {
    line-height: 46px !important;
  }
  div > .el-form-item:last-of-type {
    margin-right: 0 !important;
  }

  .el-form-item,
  /deep/ .el-form-item__label {
    line-height: 46px !important;
  }

  /deep/ .el-input__inner {
    height: 46px !important;
    line-height: 46px !important;
    padding-left: 15px !important;
  }

  .password-level {
    position: absolute;
    top: 12px;
    right: -225px;
    z-index: 2;
    height: 24px;
    display: flex;
    justify-content: center;

    &-box {
      display: inline-flex;
      justify-content: space-between;
      width: 110px;

      div {
        margin: auto;
        width: 34px;
        height: 8px;
        background: rgba(242, 245, 252, 1);
      }
    }

    &-text {
      line-height: 24px;
      padding-left: 10px;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
    }

    &-code {
      line-height: 24px;
      font-size: 14px;
      color: #f73636;
    }
  }
  .confirmPassword-pass {
    position: absolute;
    top: 10px;
    right: -30px;
    z-index: 2;
    height: 24px;
    display: flex;
    justify-content: center;
  }
}
.password-level.lower {
  .password-level-box {
    div:first-child {
      background: #f73636;
    }
  }
}

.password-level.middle {
  .password-level-box {
    div {
      background: #f78a36;
    }

    div:last-of-type {
      background: #f2f5fc;
    }
  }

  .password-level-code {
    color: #f78a36;
  }
}

.password-level.higher {
  .password-level-box {
    div {
      background: #10c77b;
    }
  }

  .password-level-code {
    color: #10c77b;
  }
}

/deep/ .el-radio {
  margin-right: 12px;
}

/deep/ .el-radio__label {
  color: #3a485e;
  padding-left: 4px !important;
}

.el-radio-button__inner,
.el-radio-group {
  line-height: 46px !important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #316cf5;
  background: #316cf5;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #3a485e;
}

.select-component {
  /deep/ .zwx-input {
    width: 416px !important;
    height: 46px !important;
    line-height: 46px !important;
    cursor: pointer;
  }

  /deep/ .el-input__suffix {
    right: 6px !important;
  }

  /deep/ .el-input__inner {
    border: 1px solid rgba(215, 218, 224, 1) !important;
    border-radius: 4px !important;
    width: 416px;
    padding-left: 15px !important;
  }

  /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
    margin-top: 0;
    line-height: 46px !important;
  }

  /deep/ .zwx-input-up {
    margin-left: -32px !important;
  }
}

.footer-button {
  width: 532px;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.next-success {
  width: 188px;
  height: 48px;
  line-height: 48px !important;
  padding: 0 42px !important;
  color: #ffffff;
  font-weight: unset;
  background: #5574df;
  border-radius: 4px;
  border: none;
}
.next-button {
  height: 36px;
  line-height: 36px !important;
  padding: 0 42px !important;
  color: #ffffff;
  font-weight: unset;
  background: #5574df;
  border-radius: 4px;
  border: none;
}

.next-button.is-disabled {
  opacity: 0.5;
}

.prev-button {
  height: 36px;
  line-height: 36px !important;
  padding: 0 42px !important;
  color: #5574df;
  font-weight: unset;
  border: 1px solid #5574df;
  // border: 1px solid #D7DAE0;
  // color: #3d3f44;
}

.register-success {
  margin-top: 100px;
  width: 228px;
  height: 188px;
  background-image: url('../../assets/images/system/img_register_success.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.register-success-title {
  margin-top: 20px;
  width: 132px;
  height: 26px;
  font-size: 22px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #3d3f44;
  line-height: 26px;
}
.zwx-form .el-form-item.is-error .el-input__inner{
  border-color:#7E91A5 !important;
}
.zwx-form .el-form-item.is-error .el-input__inner{
  border-color:#7E91A5 !important;
}
</style>

<style lang="less"></style>
